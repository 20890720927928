import React, { useEffect } from "react";
import "./Termsandconditions.css";
import { Helmet } from "react-helmet";
import ReactGA4 from "react-ga4";
import { useLocation } from "react-router-dom";
import clevertap from "clevertap-web-sdk";

function TermsandConditions() {
  const location = useLocation();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    ReactGA4.send("pageview", location.pathname);
    clevertap.event.push("Page Viewed", {
      Page_name: "Terms and conditions Page",
    });
  }, []);

  return (
    <>
      <div className="termsandconditionspage">
        <Helmet>
          <title>Fabits - Terms and Conditions</title>
          <meta
            name="description"
            content="Regulatory page displaying terms and conditions of Shareway Securities Private Limited"
          />
        </Helmet>
        {/* <div className="termsandconditionsheeding">
          <h1 className="headingoftandc">
            <span className="font__green">Terms </span>
            and Conditions
          </h1>
        </div> */}
        <div className="contentstartshere">
          <h2 className="sharewaysecuritieslimited">
            Shareway Securities Private Limited (FABITS)
          </h2>
          <p className="mt-5">
            This page contains important information regarding the terms and
            conditions which apply to your trading and demat account with
            Shareway Securities Private Limited. Shareway Securities Private
            Limited, being a Trading Member of NSE registered with Securities &
            Exchange Board of India (“SEBI”) and having Registration No.
            INZ000208134 for Cash/Derivatives segments of NSE trading on your
            account will be enabled through Shareway Securities Private Limited.
            The Depository services for your account will be enabled through
            Shareway Securities Private Limited, a Depository Participant using
            CDSL as the Depository having SEBI Registration no.: IN-DP-610-2021.
            Access to your account and the usage of your account is subject to
            your compliance with all the terms and conditions set forth herein,
            read along with all documents, including but not limited to;
            applications forms and undertakings, signed by you during account
            opening. Please read this page carefully and retain it for future
            reference.
            <br></br>
            <br></br>
            The website is owned, operated, and maintained by Shareway
            Securities Limited (hereinafter referred to as “SHAREWAY SECURITIES
            LIMITED”), a Company incorporated under the Companies Act, 2013
            having CIN: U67120TN2008PLC066952, and having its Registered Office
            at #Flat E,2nd Floor“Gilli Flower” No 6/46,23rd Street L Block Anna
            Nagar East Chennai – 600102
            <br></br>
            <br></br>
            Please note that the information contained herein is subject to
            change without notice.
          </p>
          <h3 className="heeding3standcpage">General terms and conditions</h3>
          <p>
            Before availing of online trading services, the Client shall
            complete the registration process as may be prescribed from time to
            time. The Client shall follow the instruction given on the website
            for registering himself as a client.
            <br></br>
            <br></br>
            The Client agrees that all investment and disinvestment decisions
            are based on the Client’s own evaluation of financial circumstances
            and investment objectives. This extends to any decisions made by the
            Client on the basis of any information that may be made available on
            the web site of SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN
            AS SHAREWAY SECURITIES LIMITED) . The Client will not hold nor seek
            to hold SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS
            SHAREWAY SECURITIES LIMITED) or any of its officers, directors,
            partners, employees, agents, subsidiaries, affiliates or business
            associates liable for any trading losses, cost of damage incurred by
            the Client consequent upon relying on investment information,
            research opinions or advice or any other material/information
            whatsoever on the web site, literature, brochure issued by SHAREWAY
            SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY SECURITIES
            LIMITED) or any other agency appointed/authorised by SHAREWAY
            SECURITIES PRIVATE LIMITED . The Client should seek independent
            professional advice regarding the suitability of any investment
            decisions. The Client also acknowledges that employees of SHAREWAY
            SECURITIES PRIVATE LIMITED are not authorized to give any such
            advice and that the Client will not solicit or rely upon any such
            advice from SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS
            SHAREWAY SECURITIES LIMITED) or any of its employees.
            <br></br>
            <br></br>
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) owns “Fabits” as brand and operates through this
            brand name and has Intellectual Proprietary Rights of the same.
          </p>
          <h3 className="heeding3standcpage">
            Security Precaution and Password
          </h3>
          <p>
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) will provide the client with a username and a
            trading password which will enable him to avail of the facilities of
            Online Trading through the SHAREWAY SECURITIES PRIVATE LIMITED
            website, over the telephone or in any such other manner as may be
            permitted by SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS
            SHAREWAY SECURITIES LIMITED) for availing of the services. SHAREWAY
            SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY SECURITIES
            LIMITED) may also provide the client with the username and password
            for accessing its back-office for various reports, etc. All terms
            regarding the use, reset and modification of such password shall be
            governed by information on the website.
            <br></br>
            <br></br>
            Our trading terminal system have a stringent Two-Factor
            authentication (2FA) secure system for every client to enter their
            login ID using their Client ID and Password, along with a 4-digit
            PIN OR Authentication via apps such as Google Authenticator set by
            the Client himself/herself. This 2FA mechanism is set in place as
            per SEBI & Exchange guidelines with respect to Internet Based
            Trading Rules.
            <br></br>
            <br></br>
            Each (or Shareway Securities Private Limited trading platform)
            Password has a 2FA system in place for a secure login experience by
            each client. After a client enters his/her Client ID, he/she is
            required to enter his/her (i) Password; and (ii) a 4-digit unique
            PIN set by the Client him/herself OR an authentication mechanism via
            an application such as Google® Authenticator or Microsoft®
            Authenticator. A link to create Password & 2FA are sent to all
            clients along with the welcome email while opening an account. This
            Password and 2FA set by each client, is unique to him/her, and is
            not known to anybody else apart from the client him/herself.
            Passwords and 2FA credentials are privileged information and must be
            kept in high secrecy by each client and not shared with anybody
            else.
            <br></br>
            <br></br>
            As per instructions from SEBI & the Depositories, we are also
            required to obtain the above stated Two-Factor Authentication (2FA)
            for all such sell transactions where shares are debited from the
            demat account using the Online DIS route. Therefore, the same PIN
            set by You for Your trading terminal login, will be required to be
            inputted by You each time during a debit/sell transaction from your
            holdings from Your demat account, This will be required only if You
            are using the Online DIS route and not if you have submitted the
            physical Power of Attorney,
            <br></br>
            <br></br>
            For Call & trade, each and every client is required to mention the
            last 4 digits of his/her Client ID along with his/her personalised 4
            digit Pin (known as Telephone code) which can only be seen inside
            his/her trading account, that can be entered into by the client
            him/herself only after entering the Client ID, Password, 2FA. To
            know more about creating, changing, and using Your Telephone code,
            You may call us for more details.
            <br></br>
            <br></br>
            Any Password can be reset by the You at any point of time, by
            selecting the ‘Forgot Password’ link under the login area. As soon
            as You select the ‘Forgot Password’ link, a reset link is sent to
            Your registered email ID, where the same process of setting a unique
            password is followed.
            <br></br>
            <br></br>
            The Client shall be responsible for keeping the Username and
            Password confidential and secure and shall be solely responsible for
            all orders entered and transactions done by any person whosoever
            through SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS
            SHAREWAY SECURITIES LIMITED) ’s Online Trading System using the
            Client’s Username and/or Password whether or not such person was
            authorised to do so.
            <br></br>
            <br></br>
            The Client shall immediately inform SHAREWAY SECURITIES PRIVATE
            LIMITED of any unauthorised use of the Client’s Username or Password
            with full details of such unauthorised use including the date of
            such unauthorised use, the manner in which it was unauthorisedly
            used, the transactions effected pursuant to such unauthorised use,
            etc.
            <br></br>
            <br></br>
            The Client acknowledges that he is fully aware of and understands
            the risks associated with availing of online trading services
            through internet including the risk of misuse and unauthorised use
            of his Username and/or Password by a third party and the risk of a
            person hacking into the Client’s account on SHAREWAY SECURITIES
            LIMITED’s Online Trading System and unauthorisedly routing orders on
            behalf of the Client through the System. The Client agrees that he
            shall be fully liable and responsible for any and all unauthorised
            use and misuse of his Password and/or Username and also for any and
            all acts done by any person through SHAREWAY SECURITIES PRIVATE
            LIMITED ’s Online Trading System on the Client’s Username in any
            manner whatsoever.
            <br></br>
            <br></br>
            Without prejudice to the provisions mentioned herein above, the
            Client shall immediately notify SHAREWAY SECURITIES PRIVATE LIMITED
            (FORMERLY KNOWN AS SHAREWAY SECURITIES LIMITED) in writing with full
            details if: he discovers or suspects unauthorised access through his
            Username, Password or Account, he notices discrepancies that might
            be attributable to unauthorised access, he forgets his password or
            he discovers a security flaw in SHAREWAY SECURITIES PRIVATE
            LIMITED’s Online Trading System.
          </p>
          <h3 className="heeding3standcpage">Orders</h3>
          <p>
            All orders for purchase, sale or other dealings in securities and
            other instructions routed through the SHAREWAY SECURITIES PRIVATE
            LIMITED ’s Online Trading System via the Client’s Username shall be
            deemed to have been given by the Client.
            <br></br>
            <br></br>
            The client agrees to provide information relating to customer user
            identification number, and such other information as may be required
            while placing orders on the telephone to determine the identity of
            the client.
            <br></br>
            <br></br>
            The orders and instructions and all contracts and transactions
            entered into pursuant thereto and the settlement thereof will be in
            accordance with the Exchange Provisions.
            <br></br>
            <br></br>
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) may from time to time impose and vary limits on
            the orders which the Client can place through SHAREWAY SECURITIES
            PRIVATE LIMITED’s online trading System (including exposure limits,
            turnover limits, limits as to the number, value and/or kind of
            securities in respect of which orders can be placed, the companies
            in respect of whose securities orders can be placed, etc.). The
            Client is aware and agrees that SHAREWAY SECURITIES LIMITED may need
            to vary or reduce the limits or impose new limits urgently on the
            basis of the SHAREWAY SECURITIES PRIVATE LIMITED ’s risk perception
            and other factors considered relevant by SHAREWAY SECURITIES PRIVATE
            LIMITED (FORMERLY KNOWN AS SHAREWAY SECURITIES LIMITED), and
            SHAREWAY SECURITIES PRIVATE LIMITED may be unable to inform the
            Client of such variation, reduction or imposition in advance. The
            Client agrees that SHAREWAY SECURITIES PRIVATE LIMITED shall not be
            responsible for such variation, reduction or imposition or the
            Client’s inability to route any order through SHAREWAY SECURITIES
            PRIVATE LIMITED’s Online Trading System on account of any such
            variation, reduction or imposition of limits. The Client understands
            and agrees that SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN
            AS SHAREWAY SECURITIES LIMITED) may at any time, at its sole
            discretion and without prior notice, prohibit or restrict the
            Client’s ability to place orders or trade in securities through
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) .<br></br>
            <br></br>
            Though orders will generally be routed to the Exchange’s computer
            systems within a few seconds from the time the order is placed by
            the Client on SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS
            SHAREWAY SECURITIES LIMITED) ’s Online Trading System, SHAREWAY
            SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY SECURITIES
            LIMITED) shall not be liable for any delay in the execution of any
            order or for any resultant loss on account of the delay.
            <br></br>
            <br></br>
            The client agrees SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY
            KNOWN AS SHAREWAY SECURITIES LIMITED) may impose scrip wise
            surveillance or such other conditions as to scrip wise limits, etc.
            The client also understands that SHAREWAY SECURITIES PRIVATE LIMITED
            may impose various surveillances which may differ from client to
            client on the basis of the SHAREWAY SECURITIES PRIVATE LIMITED ’s
            risk perception and other factors considered relevant by SHAREWAY
            SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY SECURITIES
            LIMITED).
            <br></br>
            <br></br>
            In case of a market order, the Client agrees that he will receive
            the price at which his order is executed by the exchange’s computer
            system; and such price may be different from the price at which the
            security is trading when his order is entered into SHAREWAY
            SECURITIES LIMITED’s Online Trading System.
          </p>
          <h3 className="heeding3standcpage">Online Account Opening</h3>
          <p>
            Online account opening using AADHAAR is currently available only for
            residents of India opening accounts for an individual (HUFs,
            corporate bodies, NRIs, etc., not eligible).
            <br></br>
            <br></br>
            When opening an account online using AADHAAR, data will be fetched
            from the KYC database and cannot be edited.
            <br></br>
            <br></br>
            Pick up of required documents related to the account opening
            procedure is subject to availability of our representatives, given
            at any particular time and location.
          </p>
          <h3 className="heeding3standcpage">
            Trading, Settlement, and Accounts
          </h3>
          <p>
            The client agrees that all orders placed through the website shall
            be forwarded by the system to the Exchange. All orders placed
            otherwise than through the website shall be forwarded by the system
            to Exchange terminals or any other order execution mechanism at the
            discretion of SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS
            SHAREWAY SECURITIES LIMITED) . In the event that the order is placed
            during the trading hours, it shall be routed to and executed on the
            market system.
            <br></br>
            <br></br>
            Online confirmation will be sent to the client by electronic mail or
            SMS after the execution of the order, trade and this shall be deemed
            to be valid delivery thereof by SHAREWAY SECURITIES PRIVATE LIMITED
            (FORMERLY KNOWN AS SHAREWAY SECURITIES LIMITED) . It shall be the
            responsibility of the client to review immediately upon receipt,
            whether delivered to him by electronic mail or any other electronic
            means all confirmations of order, transactions, or cancellations. It
            shall be the responsibility of the client to follow up with SHAREWAY
            SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY SECURITIES
            LIMITED) for all such confirmations that are not received by him
            within a stipulated time.
            <br></br>
            <br></br>
            The client shall bring any errors in any report, confirmation or
            contract note of executed trades (including execution prices,
            scripts or quantities) to SHAREWAY SECURITIES PRIVATE LIMITED
            (FORMERLY KNOWN AS SHAREWAY SECURITIES LIMITED) ’s notice in writing
            by an electronic mail or fax within twenty-four hours of receipt of
            the concerned report, confirmation or contract note. Any other
            discrepancy in the confirmation or account shall be notified by the
            client to SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS
            SHAREWAY SECURITIES LIMITED) in writing via electronic mail or fax
            within twenty-four hours from the time of receipt of the first
            notice. In all cases, SHAREWAY SECURITIES LIMITED shall have a right
            to accept or reject the client’s objection.
            <br></br>
            <br></br>
            There may be a delay in SHAREWAY SECURITIES PRIVATE LIMITED
            (FORMERLY KNOWN AS SHAREWAY SECURITIES LIMITED) receiving the
            reports of transaction, status, from the respective exchanges or
            other persons in respect of or in connection with which SHAREWAY
            SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY SECURITIES
            LIMITED) has entered into contracts or transactions on behalf of the
            clients. accordingly, SHAREWAY SECURITIES PRIVATE LIMITED may
            forward to the client late reports in respect of such transactions
            that were previously unreported to him as been expired, cancelled or
            executed. The client shall not hold SHAREWAY SECURITIES PRIVATE
            LIMITED (FORMERLY KNOWN AS SHAREWAY SECURITIES LIMITED) responsible
            for any losses suffered by the client on account of any late
            reports, statements or any errors in the report / statements
            computed by or received from any exchange.
            <br></br>
            <br></br>
            The client agrees that if, for any circumstance or for any reason,
            the markets close before the acceptance of the Order by the
            Exchange, the order may be rejected. The client agrees further, that
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) may reject Orders if the same are rejected by
            the Exchange for any reason. In case of rejection of an order due to
            rejection by the Exchange, the client agrees that the order shall
            remain declined and shall not be re-processed, in any event.
            <br></br>
            <br></br>
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) may, at its sole discretion, reject any order
            placed on the website or in any other manner due to any reason,
            including but not limited to the non-availability of funds in the
            trading account of the client, non-availability of securities in the
            Demat account of the client with a designated depository
            participant, insufficiency of margin amount if the client opts for
            margin trading, suspension of scrip- specific trading activities by
            or on an Exchange and the applicability of circuit breaker to a
            scrip in which orders are placed.
            <br></br>
            <br></br>
            The client agrees that, if the order is not accepted on the website
            for any reason, SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN
            AS SHAREWAY SECURITIES LIMITED) shall have the right to treat the
            order as having lapsed.
            <br></br>
            <br></br>
            The client is aware that the electronic trading systems either at
            the Exchange or in the office of SHAREWAY SECURITIES PRIVATE LIMITED
            (FORMERLY KNOWN AS SHAREWAY SECURITIES LIMITED) offices are
            vulnerable to temporary disruptions, breakdowns, or failures. In the
            event of non- execution of trade orders or trade cancellation due to
            the happening of such events or vulnerabilities due to failure /
            disruption / breakdown of system or link, SHAREWAY SECURITIES
            LIMITED shall be entitled to cancel relative request/(s) with the
            Client and shall not be liable to execute the desired transactions
            of the client’s. In such event, SHAREWAY SECURITIES LIMITED does not
            accept responsibility for any losses incurred / that may be incurred
            by the Client due to such eventualities which are beyond the control
            of SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) .<br></br>
            <br></br>
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) may at its sole discretion permit execution of
            orders in respect of securities, irrespective of the amount in the
            balance of the account of the client.
            <br></br>
            <br></br>
            The client agrees to abide with and be bound by all the rules,
            regulations and bye-laws of the Exchange as are in force pertaining
            to the transactions on his behalf carried out by SHAREWAY SECURITIES
            LIMITED and the orders placed by him on the website or any other
            manner.
            <br></br>
            <br></br>
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) shall not be responsible for any order, that is
            made by the Client by mistake and every order that is entered by the
            Client through the use of the allotted username and the security
            code(s) shall be deemed to be a valid order for which the Client
            shall be fully responsible.
            <br></br>
            <br></br>
            Cancellation or modification of an order pursuant to the client’s
            request in that behalf is not guaranteed. The order will be
            cancelled or modified only if the client’s request for cancellation
            and modification is received and the order is successfully cancelled
            or modified before it is executed. Market orders are subject to
            immediate execution wherever possible.
            <br></br>
            <br></br>
            The client shall not be entitled to presume an order having been
            executed, cancelled or modified until a confirmation from SHAREWAY
            SECURITIES LIMITED is received by the client. However, due to
            technical other factors the confirmation may not be immediately
            transmitted to or received by the client and such a delay shall not
            entitle the client to presume that the order has not been executed
            cancelled or modified unless and until SHAREWAY SECURITIES PRIVATE
            LIMITED has so confirmed in writing.
            <br></br>
            <br></br>
            The pending orders shall be governed as per the exchange systems,
            after the market is closed for the day.
            <br></br>
            <br></br>
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) shall issue contract notes in terms of the SEBI
            (Brokers and Sub-Brokers) Rules and Regulations, 1992, within 24
            hours of the execution of the trade. Such a contract notes, if
            issued in physical form shall be dispatched by SHAREWAY SECURITIES
            LIMITED by courier, at the address mentioned in this agreement or at
            any other address expressly informed to SHAREWAY SECURITIES LIMITED
            by the client. The client agrees that SHAREWAY SECURITIES LIMITED to
            issue the contract note in digital form which shall be sent by way
            of electronic mail to the address provided by the client. SHAREWAY
            SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY SECURITIES
            LIMITED) shall not be responsible for the non-receipt of the trade
            confirmation due to any change in the correspondence address of the
            Client not intimated to SHAREWAY SECURITIES PRIVATE LIMITED
            (FORMERLY KNOWN AS SHAREWAY SECURITIES LIMITED) in writing. Client
            is aware that it is his responsibility to review the trade
            confirmations, the contract notes, the bills, or statements of
            account immediately upon their receipt. All such confirmations and
            statements shall be deemed to have been accepted as correct if the
            client does not object in writing to any of the contents of such
            trade confirmation/intimation within 24 hours to SHAREWAY SECURITIES
            PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY SECURITIES LIMITED) .
            <br></br>
            <br></br>
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) may allow/disallow client from trading in any
            security or class of securities, or derivatives contracts and impose
            such conditions including scrip-wise conditional trading for trading
            as it may deem fit from time to time.
          </p>
          <h3 className="heeding3standcpage">Margin</h3>
          <p>
            The Client agrees and undertakes to immediately deposit with
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) such cash, securities, or other acceptable
            security, which SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN
            AS SHAREWAY SECURITIES LIMITED) may require as margin. The Client
            agrees that SHAREWAY SECURITIES PRIVATE LIMITED shall be entitled to
            require the Client to deposit with SHAREWAY SECURITIES PRIVATE
            LIMITED (FORMERLY KNOWN AS SHAREWAY SECURITIES LIMITED) a higher
            margin than that prescribed by the Exchange. SHAREWAY SECURITIES
            PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY SECURITIES LIMITED)
            shall also be entitled to require the Client to keep permanently
            with SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) a margin of a value specified by SHAREWAY
            SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY SECURITIES
            LIMITED) so long as the Client desires to avail of the Online
            Trading Service of SHAREWAY SECURITIES PRIVATE LIMITED .<br></br>
            <br></br>
            The Margin will not be interest bearing. SHAREWAY SECURITIES PRIVATE
            LIMITED shall have, at its sole discretion, the irrevocable right to
            set off a part or whole of the Margin i.e., by the way of
            appropriating of the relevant amount of cash or by sale or transfer
            of all or some of the Securities which form part of the Margin,
            against any dues of the Client or of a member of the group of the
            Client (for the purposes of these Terms, “Group” shall mean all the
            individuals, group companies, firms, entities and the persons as
            specified in the schedule to the Member Client Agreement) in the
            event of the failure of the Client or a member of the Group of the
            Client to meet any of their respective obligations under these
            Terms.
            <br></br>
            <br></br>
            The client agrees and authorises SHAREWAY SECURITIES PRIVATE LIMITED
            (FORMERLY KNOWN AS SHAREWAY SECURITIES LIMITED) to determine the
            market value of securities placed as margin after applying a haircut
            that SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) may deem appropriate. The client undertakes to
            monitor the market value of such securities on a continuous basis.
            The client further undertakes to replenish any shortfall in the
            value of the margin consequents to a fall in the market value of
            such securities placed as margin immediately whether or not SHAREWAY
            SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY SECURITIES
            LIMITED) intimates such shortfall.
            <br></br>
            <br></br>
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) may at its sole discretion prescribe the payment
            of Margin in the form of cash instead of or in addition to margin in
            form of securities. The Client accepts to comply with the
            requirement of SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN
            AS SHAREWAY SECURITIES LIMITED) with regards to payment of Margin in
            the form of cash immediately. Without prejudice to the stockbroker’s
            other rights (including the right to refer a matter to arbitration),
            the stockbroker shall be entitled to liquidate / close out all or
            any of the client’s positions for non- payment of margins or other
            amount, outstanding debts, etc., and adjust the proceeds of such
            liquidation / close out, if any, against the client’s liabilities /
            obligations. Any and all losses and financial charges on account of
            such liquidation / closing-out shall be charged to and borne by the
            client.
            <br></br>
            <br></br>
            The client agrees to abide by the exposure limits, if any, set by
            the stockbroker or by the Exchange or Clearing Corporation or SEBI
            from time to time.
            <br></br>
            <br></br>
            The client is also aware that SHAREWAY SECURITIES PRIVATE LIMITED
            (FORMERLY KNOWN AS SHAREWAY SECURITIES LIMITED) is required to
            deposit sufficient margin with the Exchange to enable all its
            eligible clients to trade subject to such limits as may be imposed
            by SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) on the basis of SHAREWAY SECURITIES PRIVATE
            LIMITEDs’ Risk perception and other factors considered relevant by
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) . However, there may be circumstances when the
            deposits made by SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN
            AS SHAREWAY SECURITIES LIMITED) with the Exchange may not be
            sufficient in times of extreme volatility and trading terminals of
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) may get temporarily suspended because of the
            cumulative effect of non-meeting of obligation by various clients as
            per this agreement. In these circumstances, no client shall have the
            right to claim any damages from SHAREWAY SECURITIES PRIVATE LIMITED
            for any loss that they might incur on account of such suspension of
            trading.
            <br></br>
            <br></br>
            The Client agrees that any securities/cash placed by him/her/it as
            margin may in turn be placed as margin by SHAREWAY SECURITIES
            LIMITED with the Exchanges or banks or such other institution as
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) may deem fit. The Client authorises SHAREWAY
            SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY SECURITIES
            LIMITED) to do all such acts, deeds and things as may be necessary
            and expedient for placing such securities/cash with the
            Exchanges/Banks/Institutions as margin.
            <br></br>
            <br></br>
            Any reference in these terms to sales or transfer of Securities by
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) shall be deemed to include sale of the
            Securities which form part of the Margin maintained by the Client
            with SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) . In exercise of SHAREWAY SECURITIES PRIVATE
            LIMITED’s right to sell securities under the Agreement, the Client
            agrees that the choice of specific securities to be sold shall be
            solely at the discretion of SHAREWAY SECURITIES PRIVATE LIMITED .
          </p>

          <h3 className="heeding3standcpage">Maintenance of Trading Account</h3>

          <p>
            Money pay-in to SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN
            AS SHAREWAY SECURITIES LIMITED) – The Client agrees that all
            payments due to SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN
            AS SHAREWAY SECURITIES LIMITED) will be made within the specified
            time and in the event of any delay, SHAREWAY SECURITIES PRIVATE
            LIMITED (FORMERLY KNOWN AS SHAREWAY SECURITIES LIMITED) may refuse,
            at their discretion, to carry out transactions or closeout the
            position and the costs/losses if any, thereof shall be borne solely
            and completely by the client. All payments made to SHAREWAY
            SECURITIES PRIVATE LIMITED shall be from the account of the client
            and shall not to be from any third party.
            <br></br>
            <br></br>
            Money pay out to SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN
            AS SHAREWAY SECURITIES LIMITED) – Notwithstanding anything contained
            in any other agreement or arrangement, if any, between the parties
            hereto, the client hereby authorizes SHAREWAY SECURITIES PRIVATE
            LIMITED (FORMERLY KNOWN AS SHAREWAY SECURITIES LIMITED) to release
            all payments due to him from the trading account maintain with
            SHAREWAY SECURITIES PRIVATE LIMITED , against specific request in
            this behalf.
            <br></br>
            <br></br>
            Securities pay-in to SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY
            KNOWN AS SHAREWAY SECURITIES LIMITED) – All delivery to be effected
            to SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) for a trade, must be made within 24 hours from
            the execution of the sale order or one day before the pay-in date,
            whichever is earlier. Losses, if any, that may accrue in the event
            of a default in completing the delivery on the exchange by SHAREWAY
            SECURITIES PRIVATE LIMITED as a result of any delay in the delivery
            by the client, shall be borne solely and completely by the client.
            Losses for the purposes of this clause shall include auction debits/
            penalty charges, if any incurred as a result of non-delivery of
            securities on the settlement date on the exchange. No third-party
            shares will be sold through SHAREWAY SECURITIES PRIVATE LIMITED
            (FORMERLY KNOWN AS SHAREWAY SECURITIES LIMITED) or third party
            payment should be made to SHAREWAY SECURITIES PRIVATE LIMITED and
            client will be solely responsible for any violation. If the client
            has sold any securities from the exchange against purchase in
            previous settlements, such sale shall be at the sole risk as to
            costs and consequences thereof of the client.
            <br></br>
            <br></br>
            Securities pay-out by SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY
            KNOWN AS SHAREWAY SECURITIES LIMITED) – SHAREWAY SECURITIES LIMITED
            may directly credit the demat account of the client with the
            depository participant or maintain the securities account with
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) on a running account basis.
            <br></br>
            <br></br>
            Provided that if the order placed by the client through the website
            or otherwise is for securities which are in the no-delivery period,
            such securities shall be credited to the trading account of the
            client only at the time of settlement of trades, as per the schedule
            of the Exchange. However, if any sum due from the client, SHAREWAY
            SECURITIES LIMITED may withholds the credit of securities to the
            demat account of the client. However, the client authorizes SHAREWAY
            SECURITIES LIMITED to withhold the securities to meet liabilities of
            client to SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS
            SHAREWAY SECURITIES LIMITED) under this agreement.
            <br></br>
            <br></br>
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) is entitled to consider any sum or money or
            security lying to the credit of the client as margin received.
          </p>

          <h3 className="heeding3standcpage">Representations and Warranties</h3>

          <p>
            The Client hereby warrants that he is capable of executing the
            present agreement and that the terms of the present are not in
            contravention of any rights of any party with whom such client has
            any agreements, at any time prior to the execution of this
            agreement.
            <br></br>
            <br></br>
            He agrees to provide and continue to provide all details about
            themselves as may be required by SHAREWAY SECURITIES PRIVATE LIMITED
            (FORMERLY KNOWN AS SHAREWAY SECURITIES LIMITED) , including but not
            restricted to PAN Number or Unique Identification Number (issued by
            SEBI) , and states all details and facts represented to SHAREWAY
            SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY SECURITIES
            LIMITED) are true.
            <br></br>
            <br></br>
            The Client is aware and acknowledges that trading over the internet
            involves many uncertain factors and complex hardware, software,
            systems, communication lines, peripherals, etc., which are
            susceptible to interruptions and dislocations; and the Online
            Trading Service of SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY
            KNOWN AS SHAREWAY SECURITIES LIMITED) may at any time be unavailable
            without further notice. SHAREWAY SECURITIES PRIVATE LIMITED and the
            Exchange do not make any representation or warranty that the Online
            Trading Service of SHAREWAY SECURITIES PRIVATE LIMITED will be
            available to the Client at all times without any interruption. The
            Client agrees that he shall not have any claim against the Exchange
            or SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) on account of any suspension, interruption,
            non-availability or malfunctioning of the Online Trading System or
            Service of SHAREWAY SECURITIES PRIVATE LIMITED or the Exchange’s
            service or systems for any reason whatsoever.
            <br></br>
            <br></br>
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) states that it has complied with and will
            continuously comply and if not proposes to comply with all statutory
            and regulatory directions to offer the Internet Trading services
            through the website Fabits.com and for dealing in cash and
            derivatives segment of the exchange.
            <br></br>
            <br></br>
            The Client warrants that all or any of the securities deposited by
            him with SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS
            SHAREWAY SECURITIES LIMITED) in respect of margin requirements or
            otherwise, are owned by him and the title thereof is clear and free
            of encumbrances.
            <br></br>
            <br></br>
            The Client/s agree to indemnify and hold SHAREWAY SECURITIES PRIVATE
            LIMITED harmless against any loss that may be suffered by it, its
            customers or a third party or any claim or action that may be
            initiated by a third party which is in any way the result of
            improper use of user ID and password by the Client/s.
            <br></br>
            <br></br>
            The Client hereby confirms and warrants that the Client authorises
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) to take all such steps on the Client’s behalf as
            may be required for provisions or to complete or settle any
            transactions entered into through or with SHAREWAY SECURITIES
            LIMITED or executed by SHAREWAY SECURITIES PRIVATE LIMITED on behalf
            of the Client. However, nothing herein shall oblige SHAREWAY
            SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY SECURITIES
            LIMITED) to take such steps.
          </p>

          <h3 className="heeding3standcpage">Fees and Brokerage</h3>

          <p>
            The Client agrees to pay Shareway Securities Private Limited any
            brokerage, commission, fees, service tax and other taxes and
            transaction charges as agreed upon and due from time to time, as
            applied to such Client’s account, transactions, and trading ledger
            by Shareway Securities Private Limited , for the services that the
            Client receives from Shareway Securities Private Limited.
            <br></br>
            <br></br>
            All fees, brokerage, and charges on Shareway Securities Private
            Limited’s platform are subject to change from time to time, subject
            to complying with rules prescribed by the Exchanges and SEBI, at
            Shareway Securities Private Limited’s discretion, with due
            notification to the Client. Each time there is any change with
            respect to fees, charges, and brokerage, Shareway Securities Private
            Limited would notify all Clients by an email and/or SMS to their
            registered email ID and contact number with all details, along with
            the date of such changes being effective.
            <br></br>
            <br></br>
            An updated schedule of charges, brokerage, fees, towards applicable
            services provided by Shareway Securities Private Limited along with
            taxes and other mandatory transaction charges is made available here
            on fabits website.
          </p>

          <h3 className="heeding3standcpage">Investment or Any Other Advice</h3>

          <p>
            The Client agrees that none of the services available on the website
            shall amount to investment advice on the part of SHAREWAY SECURITIES
            LIMITED.
            <br></br>
            <br></br>
            The Client agrees that in the event of SHAREWAY SECURITIES PRIVATE
            LIMITED or any employee or official of SHAREWAY SECURITIES PRIVATE
            LIMITED , providing any information, recommendation or advice to the
            client, the client may act upon the same at the sole risk and cost
            of the client, and SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY
            KNOWN AS SHAREWAY SECURITIES LIMITED) shall not be liable or
            responsible for the same.
            <br></br>
            <br></br>
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) , and its officers, directors, partners,
            employees, agents and affiliates will have no liability with respect
            to any investment decisions or transactions of the client.
          </p>

          <h3 className="heeding3standcpage">Mutual Funds</h3>

          <p>
            MFs is only an order collection platform that collects orders on
            behalf of clients,The Client expressly agrees that Shareway
            Securities Limited is not liable or responsible and does not
            represent or warrant for any damages regarding non-execution of
            orders or any incorrect execution of orders with regard to the funds
            chosen by the Client due to, but not being limited to, any
            link/system failure, sufficient/insufficient funds in the trading
            account which may occur at the end of the client, Shareway
            Securities Limited or the exchange platform.
            <br></br>
            <br></br>
            Funds required for mutual fund purchases on MF are not blocked on a
            real-time basis, they are debited at an end of day basis. After
            having placed any fund order through MF, if you have used the same
            funds to trade in the secondary markets, your account may go into a
            debit balance and you would be liable for all subsequent charges
            including any interest and margin penalty.
          </p>

          <h3 className="heeding3standcpage">Programmes & Initiatives</h3>

          <p>
            Shareway Securities Private Limited may from time to time have
            programmes to promote engagement and education among clients.
            Shareway Securities Private Limited runs an initiative of a 60 day
            challenge where clients participating and ending profitable after 60
            days, shall be awarded a certificate of recognition. Similarly,
            Shareway Securities Private Limited encourages clients to refer
            their friends and family to begin investing with Shareway Securities
            Private Limited. In all such cases, Shareway Securities Private
            Limited reserves the absolute right to change, withdraw, modify or
            suspend a part of or an entire programme / initiative, without any
            prior notice to any such user or client.
            <br></br>
            <br></br>
            Shareway Securities Private Limited’s decision on any condition of
            how any such initiative or programme shall operate, would be final.
          </p>

          <h3 className="heeding3standcpage">Miscellaneous</h3>

          <p>
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) does not warrant that the service will be
            uninterrupted or error-free. The service is provided in an “as is”
            and “as available” basis without warranties of any kind, either
            express or implied, including, without limitation, those of
            merchantability and fitness for a particular purpose. The client
            agrees that SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS
            SHAREWAY SECURITIES LIMITED) shall not be held responsible for
            delays in transmission of orders due to breakdown of the system or
            failure of communication facilities either due to the fault of the
            systems of SHAREWAY SECURITIES PRIVATE LIMITED or of the Exchanges
            or otherwise or for any other delay beyond the reasonable control of
            SHAREWAY SECURITIES PRIVATE LIMITED due to a breakdown or failure of
            communication facilities or for any other delay beyond the
            reasonable control of SHAREWAY SECURITIES PRIVATE LIMITED .<br></br>
            <br></br>
            All modification to this Agreement shall be made solely at the
            discretion of SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS
            SHAREWAY SECURITIES LIMITED) and shall be intimated to the client by
            a suitable modification to the terms and conditions or other
            applicable section on the website or in any other manner.
          </p>

          <h3 className="heeding3standcpage">Indemnity</h3>

          <p>
            In the event of death or insolvency of the client, winding up or
            liquidation, or their otherwise becoming incapable of receiving and
            paying for or delivering or transferring securities which the client
            has ordered to be bought or sold, SHAREWAY SECURITIES PRIVATE
            LIMITED may close out the transaction of the client and the client
            or his legal representative shall be liable for any losses, costs
            and be entitled to any surplus which may result therefrom.
            <br></br>
            <br></br>
            The client is aware that authentication technologies and strict
            securities measures are required for internet trading through order
            routed system and undertake to ensure that the password of the
            client and /or their authorized representatives are not revealed to
            any third party. The client also agrees to indemnify SHAREWAY
            SECURITIES LIMITED from any loss, injury, claim or any action
            instituted against SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY
            KNOWN AS SHAREWAY SECURITIES LIMITED) arising from the misuse of the
            password by any party.
          </p>

          <h3 className="heeding3standcpage">Force Majeure</h3>

          <p>
            SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
            SECURITIES LIMITED) shall not be responsible for delay or default in
            the performance of their obligations due to contingencies beyond
            their control, such as (including but not limited to) losses caused
            directly or indirectly by exchange or market rulings, suspension of
            trading, fire, flood, civil commotion, earthquake, war, strikes,
            failure of the systems, failure of the internet links or government
            / regulatory action.
          </p>

          <h3 className="heeding3standcpage">Severance</h3>

          <p>
            In the event of any one or more of the provisions contained in this
            Agreement becoming invalid, illegal or unenforceable in any respect
            under any law for the time being in force, the validity, legality
            and enforceability of the remaining provisions contained herein
            shall not in any way be prejudiced or affected thereto.
          </p>

          <h3 className="heeding3standcpage">Refund & Cancellation Policy</h3>

          <p>
            The Refund & Cancellation policy for all payments made towards
            account opening or any other services using any mode of payment
            shall stand as under:
          </p>

          <ol className="olol">
            <li>
              The Fees paid towards account opening charges for enabling
              equities, or any other services is non-refundable.
            </li>
            <li>
              Pick up of required documents related to the account opening
              procedure is subject to availability of our representatives, given
              at any particular time and location.
            </li>
            {/* <li>
              In case your account has not been opened by Team Shareway
              Securities Limited, after the tenth day passing by from the day of
              collection of all necessary supporting documents and receipt of
              all due authorizations from you, you may request for a full refund
              of the charges as paid by you towards account opening.
            </li>
            <li>
              In case you have paid the charges relating to account opening
              multiple times, please create a ticket here on our fabits website
              and we will initiate the necessary procedure to refund your money.
            </li> */}
          </ol>
          {/* <p>
            Note: The completion of the refund procedure is subject to agencies
            such as banks, payment gateways.
          </p> */}

          <h3 className="heeding3standcpage">Investor Redressal Mechanism</h3>
          <p>
            Investor Satisfaction being of utmost importance, for which
            necessary mechanism and systems are in place. All the investor
            grievances are monitored and redressed by the Compliance Officer of
            the Company who reports directly to the Board of Directors, and this
            essentially takes care of the necessary escalation in case of any
            long pending and serious compliant, otherwise the same are taken
            care of by the Compliance Officer with the support of the Compliance
            team. The clients can email their grievances on the email id
            support@fabits.com which is displayed on our website and the same
            also mentioned on the contract notes and other correspondence if any
            sent to the client from time to time. All the mails received are
            duly monitored and redressed by the Compliance Officer. The register
            of grievances is centrally maintained for better control and
            monitoring, Thus the grievances received in writing, mail, verbally,
            etc. are centrally monitored and redressed and recorded with all the
            necessary steps taken for redressal of the same. Proper analysis is
            undertaken to analyse the nature of complaints received, pattern of
            complaints and concentration if any at a particular location, so
            that every effort is taken to remove the root cause of grievance by
            the client. Periodic visits and inspections are undertaken for the
            branches and authorised partners to get the feedback of clients on
            the issues. Depending on the feedback received necessary corrective
            steps are introduced into the system. For ensuring that the funds
            received from the particular clients are properly accounted for, no
            cheques and cash are collected, at various locations or at the Head
            Office where centralized accounting is maintained, by which it is
            ensured that the funds are credited to the account of the correct
            client and also from the particular accounts mentioned in the client
            account master in the Back office software maintained centrally. No
            other separate software is maintained for maintaining client account
            details. Thus, every attempt is made to redress the
            grievance/problems of the clients and introduce systematic changes
            into the processes so that such instances are avoided.
          </p>

          <h3 className="heeding3standcpage">Notice</h3>

          <p>
            All notices, correspondences or communications issued under this
            agreement shall be served in any one or more of the following modes
            of communications and such notice or communication shall be served
            at the ordinary place of residence and/or last known web address /
            residing address and / or at the ordinary business address of the
            party to this agreement such as –
          </p>

          <ol className="olol">
            <li>By hand</li>
            <li>Delivery by post</li>
            <li>By registered post</li>
            <li>Under certificate of posting</li>
            <li>By email or fax</li>
            <li>
              By affixing it on the door at the last known business or
              residential address.
            </li>
            <li>
              By oral communication to the party or on the last known telephone
              number or on the recording machine of such number.
            </li>
            <li>
              By advertising in at least one prominent daily newspaper having
              circulation in the area where the last known business or
              residential address of the party is situated.
            </li>
            <li>
              By notice posted on the notice board of the Exchange if no address
              is known. Any communication sent by SHAREWAY SECURITIES PRIVATE
              LIMITED to the Client shall be deemed to have been properly
              delivered or served, if such communication is returned on SHAREWAY
              SECURITIES LIMITED as unclaimed / refused / undelivered, if the
              same was sent in any one more of the above modes of communication
              to the ordinary place of residence and / or last known web address
              /residing address and / or at the ordinary business address of the
              party to this agreement.
            </li>
          </ol>

          <h3 className="heeding3standcpage">
            Payment gateway terms and conditions{" "}
          </h3>
          <p>
            <p>
              This document is an electronic record in terms of Information
              Technology Act, 2000 and rules there under as applicable and the
              amended provisions pertaining to electronic records in various
              statutes as amended by the Information Technology Act, 2000. This
              electronic record is generated by a computer system and does not
              require any physical or digital signatures. This document is
              published in accordance with the provisions of Rule 3 (1) of the
              Information Technology (Intermediaries guidelines) Rules, 2011
              that require publishing the rules and regulations, privacy policy
              and Terms of Use for access or usage of domain name{" "}
              <a
                href="https://www.fabits.com"
                target="_blank"
                style={{ textDecoration: "none", color: "black" }}
                rel="noreferrer"
              >
                https://www. fabits.com
              </a>{" "}
              ('Website'), including the related mobile site and mobile
              application (hereinafter referred to as 'Platform').
            </p>
            <br />
            <p>
              The Platform is owned by SHAREWAY SECURITIES PRIVATE LIMITED , a
              company incorporated under the Companies Act, 1956 with its
              <ul>
                <li>
                  registered office at <br />
                  <span style={{ fontWeight: "bolder" }}>
                    Old no 46 New no 6, Gilli flower, Flat, 2nd floor, 23rd
                    street, Anna Nagar East, Chennai 600102
                  </span>{" "}
                </li>
                <li>
                  and business office at <br />
                  <span>
                    294/1, 1st floor, Fabits (Shareway Securities Ltd.), 7th
                    Cross Rd, above Union Bank, Domlur 1st Stage, Domlur,
                    Bengaluru, Karnataka, 560071
                  </span>{" "}
                </li>
              </ul>
              (hereinafter referred to as ‘Platform Owner’, 'we', 'us', 'our')..
            </p>
            <br />
            <p>
              Your use of the Platform and services and tools are governed by
              the following terms and conditions (“Terms of Use”) as applicable
              to the Platform including the applicable policies which are
              incorporated herein by way of reference. If You transact on the
              Platform, You shall be subject to the policies that are applicable
              to the Platform for such transaction. By mere use of the Platform,
              You shall be contracting with the Platform Owner and these terms
              and conditions including the policies constitute Your binding
              obligations, with Platform Owner. These Terms of Use relate to
              your use of our website, goods (as applicable) or services (as
              applicable) (collectively, 'Services'). Any terms and conditions
              proposed by You which are in addition to or which conflict with
              these Terms of Use are expressly rejected by the Platform Owner
              and shall be of no force or effect. These Terms of Use can be
              modified at any time without assigning any reason. It is your
              responsibility to periodically review these Terms of Use to stay
              informed of updates..
            </p>
            <br />
            <p>
              For the purpose of these Terms of Use, wherever the context so
              requires ‘you’, 'your' or ‘user’ shall mean any natural or legal
              person who has agreed to become a user/buyer on the Platform..
              ACCESSING, BROWSING OR OTHERWISE USING THE PLATFORM INDICATES YOUR
              AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF
              USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING..
            </p>
            <br />
            <p>
              The use of Platform and/or availing of our Services is subject to
              the following Terms of Use:
              <ul>
                <li>
                  To access and use the Services, you agree to provide true,
                  accurate and complete information to us during and after
                  registration, and you shall be responsible for all acts done
                  through the use of your registered account on the Platform..
                </li>
                <li>
                  Neither we nor any third parties provide any warranty or
                  guarantee as to the accuracy, timeliness, performance,
                  completeness or suitability of the information and materials
                  offered on this website or through the Services, for any
                  specific purpose. You acknowledge that such information and
                  materials may contain inaccuracies or errors and we expressly
                  exclude liability for any such inaccuracies or errors to the
                  fullest extent permitted by law..
                </li>
                <li>
                  Your use of our Services and the Platform is solely and
                  entirely at your own risk and discretion for which we shall
                  not be liable to you in any manner. You are required to
                  independently assess and ensure that the Services meet your
                  requirements.. The contents of the Platform and the Services
                  are proprietary to us and are licensed to us.
                </li>
                <li>
                  You will not have any authority to claim any intellectual
                  property rights, title, or interest in its contents. The
                  contents includes and is not limited to the design, layout,
                  look and graphics..
                </li>
                <li>
                  You acknowledge that unauthorized use of the Platform and/or
                  the Services may lead to action against you as per these Terms
                  of Use and/or applicable laws..
                </li>
                <li>
                  You agree to pay us the charges associated with availing the
                  Services..
                </li>
                <li>
                  You agree not to use the Platform and/ or Services for any
                  purpose that is unlawful, illegal or forbidden by these Terms,
                  or Indian or local laws that might apply to you.
                </li>
                <li>
                  You agree and acknowledge that website and the Services may
                  contain links to other third party websites. On accessing
                  these links, you will be governed by the terms of use, privacy
                  policy and such other policies of such third party websites.
                  These links are provided for your convenience for provide
                  further information..
                </li>
                <li>
                  You understand that upon initiating a transaction for availing
                  the Services you are entering into a legally binding and
                  enforceable contract with the Platform Owner for the
                  Services..
                </li>
                <li>
                  You shall indemnify and hold harmless Platform Owner, its
                  affiliates, group companies (as applicable) and their
                  respective officers, directors, agents, and employees, from
                  any claim or demand, or actions including reasonable
                  attorney's fees, made by any third party or penalty imposed
                  due to or arising out of Your breach of this Terms of Use,
                  privacy Policy and other Policies, or Your violation of any
                  law, rules or regulations or the rights (including
                  infringement of intellectual property rights) of a third
                  party.
                </li>
              </ul>
            </p>
            <br />
            <p>
              Not withstanding anything contained in these Terms of Use, the
              parties shall not be liable for any failure to perform an
              obligation under these Terms if performance is prevented or
              delayed by a force majeure event.. These Terms and any dispute or
              claim relating to it, or its enforceability, shall be governed by
              and construed in accordance with the laws of India.. All disputes
              arising out of or in connection with these Terms shall be subject
              to the exclusive{" "}
              <span style={{ fontWeight: "bolder" }}>BENGALURU KARNATAKA</span>{" "}
              jurisdiction of the courts in and . All concerns or communications
              relating to these Terms must be communicated to us using the
              contact information provided on this website.
            </p>
          </p>

          <p className="copyrightssection">
            &copy; 2010 – {currentYear}, Shareway Securities Private Limited
            Broking Ltd. All rights reserved.
          </p>
        </div>
      </div>
    </>
  );
}

export default TermsandConditions;

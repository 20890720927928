const Investor_Charter_DP =
  "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Investor_Charter_DP.pdf";

const Bank_Details =
  "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Bank-Details.pdf";
const Fabits_Video =
  "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Fabits-Video.mp4";

const Basic_details_Key_Managerial_Personnels_details =
  "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Charter/Basic details Key Managerial Personnels details.pdf";
const Copy_of_Annexure_B_as_on_1_12_2023 =
  "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Charter/Copy of Annexure B-as on 1-12-2023.xlsx";
const Procedure_for_filing_a_complaint_on_designated_email_id =
  "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Charter/Procedure for filing a complaint on designated email id.pdf";
const Procedure_for_finding_out_the_status_of_the_Complaint =
  "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Charter/Procedure for finding out the status of the Complaint.pdf";
const Process_for_Demat_Account_Opening =
  "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Charter/Process for Demat Account Opening.pdf";

export { Bank_Details };
export { Fabits_Video };
export { Investor_Charter_DP };

export let Charter = {
  Basic_details_Key_Managerial_Personnels_details,
  Copy_of_Annexure_B_as_on_1_12_2023,
  Procedure_for_filing_a_complaint_on_designated_email_id,
  Procedure_for_finding_out_the_status_of_the_Complaint,
  Process_for_Demat_Account_Opening,
};

export let Complaints = {
  APR_22:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/APR-22.pdf",
  APR_23:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/APR-23.pdf",
  APR_24:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/APR-24.pdf",
  AUG_22:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/AUG-22.pdf",
  AUG_23:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/AUG-23.pdf",
  DEC_21:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/DEC-21.pdf",
  DEC_22:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/DEC-22.pdf",
  DEC_23:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/DEC-23.pdf",
  FEB_22:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/FEB-22.pdf",
  FEB_23:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/FEB-23.pdf",
  FEB_24:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/FEB-24.pdf",
  JAN_22:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/JAN-22.pdf",
  JAN_23:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/JAN-23.pdf",
  JAN_24:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/JAN-24.pdf",
  JUL_22:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/JUL-22.pdf",
  JUL_23:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/JUL-23.pdf",
  JUL_24:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/JUL-24.pdf",
  JUN_22:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/JUN-22.pdf",
  JUN_23:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/JUN-23.pdf",
  JUN_24:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/June-24.pdf",
  MAR_22:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/MAR-22.pdf",
  MAR_23:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/MAR-23.pdf",
  MAR_24:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/MAR-24.pdf",
  MAY_22:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/MAY-22.pdf",
  MAY_23:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/MAY-23.pdf",
  MAY_24:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/MAY-24.pdf",
  NOV_22:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/NOV-22.pdf",
  NOV_23:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/NOV-23.pdf",
  OCT_22:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/OCT-22.pdf",
  OCT_23:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/OCT-23.pdf",
  SEP_22:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/SEP-22.pdf",
  SEP_23:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/SEP-23.pdf",
  AUG_24:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/AUG-24.pdf",
  SEP_24:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/SEP-24.pdf",
  OCT_24:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/OCT-24.pdf",
  NOV_24:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/NOV-24.pdf",
  DEC_24:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/DEC-24.pdf",
  JAN_25:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/JAN-25.pdf",
  FEB_25:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/FEB-25.pdf",
  MAR_25:
    "https://d3lgi69rk7563.cloudfront.net/assets/FabitsAssets/Complaints/Mar-25.pdf",
};
